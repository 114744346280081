import React              from 'react'

// import BigUglyThumb       from './../media/images/thumbs/big-ugly-thumb.webp'
// import ErinsVoiceThumb    from './../media/images/thumbs/erins-voice-thumb.webp'
// import GlassJarThumb      from './../media/images/thumbs/glassjar-thumb.webp'
// import KnowingThenThumb   from './../media/images/thumbs/knowing-then-thumb.webp'
// import NewTumblThumb      from './../media/images/thumbs/newtumbl-thumb.webp'
// import RushFantasyThumb   from './../media/images/thumbs/rush-fantasy-thumb.webp'
// import VRThumb            from './../media/images/thumbs/vr-thumb.webp'
import GregoryDragonThumb from './../media/images/thumbs/gregory-dragon-thumb.webp'
import HiveThumb          from './../media/images/thumbs/hive-thumb.webp'
import InsightThumb       from './../media/images/thumbs/insight-thumb.webp'
import ParamountThumb     from './../media/images/thumbs/paramount-thumb.webp'
import RaresThumb         from './../media/images/thumbs/rares-thumb.webp'

// import BlackList          from './../media/images/blacklist.svg'
import Emmy2019           from './../media/images/emmy.svg'
import TechStars          from './../media/images/techstars.svg'
import UpWorthy           from './../media/images/upworthy.svg'


const BigUglyContent       = React.lazy(() => import('./../components/projects/BigUgly'));
const ErinsVoiceContent    = React.lazy(() => import('./../components/projects/ErinsVoice'));
const GlassJarContent      = React.lazy(() => import('./../components/projects/GlassJar'));
const GregoryDragonContent = React.lazy(() => import('./../components/projects/GregoryDragon'));
const HiveContent          = React.lazy(() => import('./../components/projects/Hive'));
const InsightContent       = React.lazy(() => import('./../components/projects/Insight'));
const KnowingThenContent   = React.lazy(() => import('./../components/projects/KnowingThen'));
const NewTumblContent      = React.lazy(() => import('./../components/projects/NewTumbl'));
const ParamountContent     = React.lazy(() => import('./../components/projects/Paramount'));
const RaresContent         = React.lazy(() => import('./../components/projects/Rares'));
const RushFantasyContent   = React.lazy(() => import('./../components/projects/RushFantasy'));
const VRContent            = React.lazy(() => import('./../components/projects/VR'));

const MainGallery = {
  InsightThumb: {
    src  : InsightThumb,
    title: 'JPL / Nasa',
    content: InsightContent,
    accolade: Emmy2019
  },
  RaresThumb: {
    src  : RaresThumb,
    title: 'Rares',
    content: RaresContent,
    accolade: TechStars
  },  
  // GlassJarThumb: {
  //   src  : GlassJarThumb,
  //   title: 'Glass Jar',
  //   content: GlassJarContent
  // },
  ParamountThumb: {
    src  : ParamountThumb,
    title: 'Paramount International',
    content: ParamountContent
  },
  // ErinsVoiceThumb: {
  //   src  : ErinsVoiceThumb,
  //   title: 'Erin\'s Voice',
  //   content: ErinsVoiceContent,
  //   accolade: BlackList
  // },
  // BigUglyThumb: {
  //   src  : BigUglyThumb,
  //   title: 'Big Ugly Poker',
  //   content: BigUglyContent
  // },
  GregoryDragonThumb: {
    src  : GregoryDragonThumb,
    title: 'Gregory Dragon',
    content: GregoryDragonContent,
    accolade: UpWorthy
  },
  // NewTumblThumb: {
  //   src  : NewTumblThumb,
  //   title: 'newTumbl',
  //   content: NewTumblContent
  // },
  // VRThumbThumb: {
  //   src  : VRThumb,
  //   title: 'Studio Transcendant',
  //   content: VRContent
  // },
  // RushFantasyThumb: {
  //   src  : RushFantasyThumb,
  //   title: 'Rush Fantasy',
  //   content: RushFantasyContent
  // },
  // KnowingThenThumb: {
  //   src  : KnowingThenThumb,
  //   title: 'Knowing Then',
  //   content: KnowingThenContent
  // },
  HiveThumb: {
    src  : HiveThumb,
    title: 'Hive',
    content: HiveContent
  },
};

export default MainGallery;