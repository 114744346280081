import { useEffect, useRef, useState } from "react";

import GradientLight from "./components/GradientLight";
import Navigation from "./components/Navigation";
import Gallery from "./components/Gallery";
import MobileGallery from "./components/MobileGallery";
import Contact from "./components/Contact";

import MainGallery from "./data/mainGallery";

import "./css/normalize.css";
import "./css/portfolio.css";
import Landing from "./components/Landing";
import RotateDevice from "./components/RotateNotice";
import Tutorials from "./components/tutorials/Tutorials";

const App = () => {
  const navRef = useRef<HTMLUListElement | null>(null);

  const sections = ['Hello', 'Portfolio', 'Tutorials', 'Contact'];
  const [activeSection, setActiveSection] = useState<string>('Hello');

  const [navDimensions, setNavDimensions] = useState({ width: 0, height: 0 });
  const userInitiated = useRef<boolean>(false);

  const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;
  const hasTouch = "ontouchstart" in window;

  useEffect(() => {
    let resizeTimer: string | number | NodeJS.Timeout | undefined;
  
    const updateNavDimensions = () => {
      clearTimeout(resizeTimer);
  
      resizeTimer = setTimeout(() => {
        if (navRef.current) {
          setNavDimensions({
            width: navRef.current.offsetWidth,
            height: navRef.current.offsetHeight,
          });
        }
      }, 300); 
    };
  
    updateNavDimensions();
  
    window.addEventListener('resize', updateNavDimensions);
  
    return () => {
      window.removeEventListener('resize', updateNavDimensions);
      clearTimeout(resizeTimer); 
    };
  }, []); 
  


  const handleUserNav = (clickedSection: string) => {
    userInitiated.current = true;
    console.log(userInitiated.current)
    setActiveSection(clickedSection);

    setTimeout(() => userInitiated.current = false, 1000);
  }

  return (
    <div className="gls__portfolio-body">
      <RotateDevice/>
        {/* <h1>{activeSection}</h1> */}
      <section id="Hello" className="gls__section gls__section--landing">

      <div className="gls__nav-backing__holder">
        <div
          className="gls__nav-backing"
          style={{
            width: `${navDimensions.width}px`,
            height: `${navDimensions.height}px`,
          }}
        ></div>
        </div>
        <Landing
        setActiveSection={handleUserNav}/>
      </section>

      <section id="Portfolio" className="gls__section gls__section--work">
        <div className="gls__nav-backing__holder">
        <div
          className="gls__nav-backing"
          style={{
            width: `${navDimensions.width}px`,
            height: `${navDimensions.height}px`,
          }}
        ></div>
        </div>
        <div className="gls__section__sub">
          <GradientLight
            colors={["#688197", "#688197", "#688197", "#4a5865"]}
          />
          {isSmallScreen && hasTouch ? (
            <MobileGallery items={MainGallery} />
          ) : (
            <Gallery items={MainGallery} />
          )}
        </div>
      </section>

      <section id="Tutorials" className="gls__section gls__section--tutorials">
        <div className="gls__nav-backing__holder">
          <div
            className="gls__nav-backing"
            style={{
              width: `${navDimensions.width}px`,
              height: `${navDimensions.height}px`,
            }}
          ></div>
        </div>
        <Tutorials/>
      </section>

      <section id="Contact" className="gls__section gls__section--contact">
        <Contact />
      </section>
      <Navigation
        navRef={navRef}
        sections={sections}
        activeSection={activeSection}
        setActiveSection={setActiveSection}
        userInitiated={userInitiated} 
      />

    </div>
  );
};

export default App;
