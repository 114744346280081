import React, { useState } from 'react';

import YoursTruly from './../media/images/yours-truly.png'

import './../css/contact.css'

const Contact: React.FC = () => {
  const [formData, setFormData] = useState({
    name   : '',
    email  : '',
    message: ''
  });

  const [feedbackMessage, setFeedbackMessage] = useState<string | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await fetch('https://www.artofsullivan.com/sendmail.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(formData)
      });
      const result = await response.text();
      setFeedbackMessage(result);
    } catch (error) {
      setFeedbackMessage('Error sending message. Please try again.');
    }
  };

  return (
    <div className='gls__contact'>
      <div className='gls__contact-group'>
        <div className='gls__contact-group__sub gls__contact-group__sub--form'>
          <div className={`gls__form-group__form ${!feedbackMessage && 'open'}`}>
            <h1>Say <span>hi!</span></h1>
            <p>Get in touch to discuss how I might help you bring your vision to life.</p>
            <form onSubmit={handleSubmit}>
              <div className='gls__form-group'>
                <label htmlFor='name'>Name:</label>
                <input type='text' id='name' name='name' value={formData.name} onChange={handleChange} required />
              </div>

              <div className='gls__form-group'>
                <label htmlFor='email'>Email:</label>
                <input type='email' id='email' name='email' value={formData.email} onChange={handleChange} required />
              </div>

              <div className='gls__form-group'>
                <label htmlFor='message'>Message:</label>
                <textarea id='message' name='message' rows={4} value={formData.message} onChange={handleChange} required></textarea>
              </div>

              <div className='gls__form-group'>
                <button type='submit'>Send Message</button>
              </div>
            </form>
          </div>
          <div className={`gls__form-group__message ${feedbackMessage && 'open'}`}>
            <p>{feedbackMessage}</p>
          </div>
        </div>
        <div className='gls__contact-group__sub gls__contact-group__sub--image'>
          <img src={YoursTruly} alt='Yours Truly, Greg Sullivan' />
        </div>
      </div>
    </div>
  );
};

export default Contact;
