import React, { useRef, useState, useEffect, useCallback } from 'react';

  // Possible states for gallery items
type GalleryState = 'active' | 'stacked' | 'default';

  // Define props type for GalleryItem component
interface GalleryItemProps {
  src       : string;
  title     : string;
  style    ?: React.CSSProperties;
  onClick  ?: () => void;
  className?: string;
  itemState?: GalleryState;
  accolade ?: string;  
}

const MobileGalleryItem: React.FC<GalleryItemProps> = ({
  src,
  title,
  onClick,
  style,
  className = '',
  itemState = 'default',
  accolade  = '',

}) => {
    // Reference to the gallery item's DOM element
  const ref = useRef<HTMLDivElement | null>(null);

    // Local state for various styles that can be applied to gallery item
    const [styles, setStyles] = useState({
      shadow  : '0px 0px 0px rgba(65, 88, 110, 0)',
    });
    

  const {
    shadow,
  } = styles;
  
    // Ref to hold current state of gallery item for use in effects/callbacks
  const itemStateRef = useRef(itemState);

    // Ref to hold the last known position of the mouse
  const mousePosition = useRef({
    x: window.innerWidth / 2,
    y: window.innerHeight / 2,
  });

    // Clamp a value between min and max
  const clamp = (value: number, min: number, max: number): number =>
    Math.min(Math.max(value, min), max);

    // Remaps a given input between a specified range and then inverts it
  const remapAndInvert = (
  input   : number,
  minClamp: number = 0.05,
  maxClamp: number = 1
  )       : number => {
    const remapRange   = maxClamp - minClamp;
    const clampedValue = clamp(input, minClamp, maxClamp);
    return 1 - (clampedValue - minClamp) / remapRange;
  };

    // Calculate the scale of the item based on mouse distance and item state
  const calculateScale = (
  distance: number,
  minScale: number = 0.8,
  maxScale: number = 1,
  state   : 'active' | 'stacked' | 'default'
  )       : number => {
      // Fixed scales for active and stacked states
    if (state === 'active') {
      return 1;
    }
    if (state === 'stacked') {
      return 0.5;
    }

    const activeDistance = remapAndInvert(
      distance / (window.innerWidth * 1.25)
    );

    return Math.max(minScale, maxScale * activeDistance);
  };

    // Calculate the shadow based on mouse position and item state
  const calculateShadow = (
    dx             : number,
    dy             : number,
    distance       : number,
    maxShadowOffset: number = 50,
    maxShadowBlur  : number = 50,
    minShadowBlur  : number = 10
  ) => {
      // Determine shadow offsets based on mouse position
    const shadowOffsetX = -(dx / window.innerWidth) * maxShadowOffset;
    const shadowOffsetY = -(dy / window.innerHeight) * maxShadowOffset;

    const activeDistance = remapAndInvert(
      distance / (window.innerWidth * 1.25)
    );

      // Calculate the shadow blur
    const shadowBlur = clamp(
      maxShadowBlur - maxShadowBlur * activeDistance,
      minShadowBlur,
      maxShadowBlur
    );

      // If the item state is 'active' or 'stacked', set shadow opacity to 0 (transparent)
    const maxOpacity = 1;

    const shadowOpacity          = 
          itemStateRef.current === 'active' || itemStateRef.current === 'stacked'
        ? 0.1
        :  Math.max(0.5, Math.min(maxOpacity, maxOpacity * activeDistance));

    return `${shadowOffsetX}px ${shadowOffsetY}px ${Math.max(
      0,
      shadowBlur
    )}px rgba(65, 88, 110, ${shadowOpacity})`;
  };

  // Compute styles for gallery item based on mouse position
  const computeStyles = useCallback((x: number, y: number) => {
    if (ref.current) {
      const rect    = ref.current.getBoundingClientRect();
      const centerX = rect.left + rect.width / 2;
      const centerY = rect.top + rect.height / 2;
  
      const dx       = x - centerX;
      const dy       = y - centerY;
      const distance = Math.sqrt(dx * dx + dy * dy);
  
      requestAnimationFrame(() => {
        setStyles((prevState) => ({
          ...prevState,
          // scale : calculateScale(distance, 0.8, 1, itemStateRef.current),
          shadow: calculateShadow(dx, dy, distance),
        }));
      });
    }
  }, []);
    

    // Update styles based on the current state of the gallery item
  useEffect(() => {
    itemStateRef.current = itemState;

      // Determine styles based on item state
    if (itemStateRef.current === 'stacked') {
      setStyles((prevState) => ({
        ...prevState,
      }));
    } else if (itemStateRef.current === 'active') {
      setStyles((prevState) => ({
        ...prevState,
        rotation: 0,
      }));
    } else {
      setStyles((prevState) => ({
        ...prevState,
        rotation: 0,
        position: {
          x: 0,
          y: 0,
        },
      }));
    }

    computeStyles(mousePosition.current.x, mousePosition.current.y);
  }, [itemState]);

    // Handle mouse movement to update styles based on mouse position
  const handleMouseMove = useCallback((e: MouseEvent) => {
    mousePosition.current.x = e.clientX;
    mousePosition.current.y = e.clientY;
    computeStyles(e.clientX, e.clientY);
  }, []);

    // Handle scroll events to update styles
  const handleScroll = useCallback(() => {
    computeStyles(mousePosition.current.x, mousePosition.current.y);
  }, []);

    // Attach and cleanup event listeners for mouse move and scroll
  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove, { passive: true });
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleMouseMove, handleScroll]);

  function toValidCSSClassName(s: string): string {
    return s
      .toLowerCase()
      .replace(/\s+/g, '-')
      .replace(/[^a-z0-9-_]/g, '');
  }

  return (
    <div
      ref       = {ref}
      className = {`gls__gallery__card gls__gallery__card--mobile ${className}${
        itemState === 'active' ? ' active' : ''
      }${itemState === 'stacked' ? ' stacked' : ''} ${toValidCSSClassName(
        title
      )}`}
      // style   = {{ ...style}}
      onClick = {onClick}
    >
      <div
            // style = {{ ...style}}
      >
        <img
          style={{
            boxShadow: shadow,
          }}
          className = 'gls__gallery__thumb'
          src       = {src}
          alt       = {title}
        />
        {accolade && (
          <img
          style={{
            filter    : `drop-shadow(${shadow})`,
          }}
            className = 'gls__gallery__accolade'
            src       = {accolade}
            alt       = 'Award Winner'
          />
        )}
      </div>
    </div>
  );
};

export default MobileGalleryItem;
