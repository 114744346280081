const WorkoutTutorial = {
  1: {
    Title   : "Kickoff: Intro and Setup",
    Duration: "3 Minutes",
    Topics  : ["React Setup", "TypeScript Basics", "Vite Project Initialization", "Normalize.css"],
    Status  : "Live",
    URL     : "https://youtu.be/GO9uPnpaNUo",
    Thumb   : "/media/images/tutorials/workout/WorkoutThumb_01.jpg",
    ThumbAlt: "The Apple Fitness data overlay we'll be creating."
  },
  2: {
    Title   : "Rolling Action: The Video Component",
    Duration: "6 Minutes",
    Topics  : ["React Components", "HTML5 Video Integration", "CSS Flexbox Layout"],
    Status  : "Live",
    URL     : "https://youtu.be/cYsToxuPB_U",
    Thumb   : "/media/images/tutorials/workout/WorkoutThumb_02.jpg",
    ThumbAlt: "Initial video player setup."
  },
  3: {
    Title   : "Building Blocks: Structures and Fonts",
    Duration: "6 Minutes",
    Topics  : ["Web Fonts Integration"],
    Status  : "Live",
    URL     : "https://youtu.be/ksCgRu9OBao",
    Thumb   : "/media/images/tutorials/workout/WorkoutThumb_03.jpg",
    ThumbAlt: "Setting up basic structures."
  },
  4: {
    Title   : "Typographic Harmony: Dynamic CSS",
    Duration: "10 Minutes",
    Topics  : ["CSS Variables", "Responsive Typography", "Flexbox"],
    Status  : "Live",
    URL     : "https://youtu.be/gFY_9AVRqnk",
    Thumb   : "/media/images/tutorials/workout/WorkoutThumb_04.jpg",
    ThumbAlt: "Refining layout with dynamic CSS."
  },
  5: {
    Title   : "The Glow Up: Crafting the Burn Bar",
    Duration: "30 Minutes",
    Topics  : ["SVG Manipulation", "CSS Masking Techniques", "CSS Blend Modes", "Interactive UI Elements"],
    Status  : "Live",
    URL     : "https://youtu.be/ig0MdenBFRM",
    Thumb   : "/media/images/tutorials/workout/WorkoutThumb_05.jpg",
    ThumbAlt: "Creating the Burn Bar component."
  },
  6: {
    Title   : "State of Play: Video Player Enhanced",
    Duration: "9 Minutes",
    Topics  : ["React State Management", "JSON Metadata Handling", "Conditional Rendering"],
    Status  : "Live",
    URL     : "https://youtu.be/l-WRvZlVnss",
    Thumb   : "/media/images/tutorials/workout/WorkoutThumb_06.jpg",
    ThumbAlt: "Enhancing the video player with state."
  },
  7: {
    Title   : "Timekeepers: Utility for Time Management",
    Duration: "14 Minutes",
    Topics  : ["JavaScript Time Functions", "Custom Time Formatting"],
    Status  : "Live",
    URL     : "https://youtu.be/NdCQaz4XSvw",
    Thumb   : "/media/images/tutorials/workout/WorkoutThumb_07.jpg",
    ThumbAlt: "Building a time utility."
  },
  8: {
    Title   : "Metadata Magic: Dynamic Event Handling",
    Duration: "12 Minutes",
    Topics  : ["External JSON Data Integration", "Dynamic Data Driven UI"],
    Status  : "Live",
    URL     : "https://youtu.be/7FWw80liKXg",
    Thumb   : "/media/images/tutorials/workout/WorkoutThumb_08.jpg",
    ThumbAlt: "Dynamic metadata usage."
  },
  9: {
    Title   : "Class Act: Dynamic Styles and Animation",
    Duration: "4 Minutes",
    Topics  : ["CSS Class Manipulation", "CSS Transitions and Animations", "React Component Lifecycle for Animations"],
    Status  : "Live",
    URL     : "https://youtu.be/Ai8zMA9F7cY",
    Thumb   : "/media/images/tutorials/workout/WorkoutThumb_09.jpg",
    ThumbAlt: "Applying dynamic CSS classes."
  },
  10: {
    Title   : "Revenge of the Metadata: Stateful Styles",
    Duration: "3 Minutes",
    Topics  : ["Advanced State Management", "Dynamic Interaction with JSON", "React Context for State Propagation"],
    Status  : "Live",
    URL     : "https://youtu.be/kneIRc4QKXk",
    Thumb   : "/media/images/tutorials/workout/WorkoutThumb_10.jpg",
    ThumbAlt: "Advanced metadata handling."
  },
  11: {
    Title   : "Countdown Conundrum: Interval Timers",
    Duration: "23 Minutes",
    Topics  : ["Building a Countdown Timer", "Inline Style Manipulation", "Time Synchronization with UI"],
    Status  : "Live",
    URL     : "https://youtu.be/2vPLEw6PtzE",
    Thumb   : "/media/images/tutorials/workout/WorkoutThumb_11.jpg",
    ThumbAlt: "Building an interval timer."
  },
  12: {
    Title   : "Grid Mastery: Dynamic Div Sizing",
    Duration: "15 Minutes",
    Topics  : ["CSS Grid Advanced Techniques", "Content-Aware Grid Cells", "Responsive Grid Layouts"],
    Status  : "Live",
    URL     : "https://youtu.be/ehvlzRhck2k",
    Thumb   : "/media/images/tutorials/workout/WorkoutThumb_12.jpg",
    ThumbAlt: "Advanced CSS Grid usage."
  },
  13: {
    Title   : "Final Flourishes: Polishing the UI",
    Duration: "11 Minutes",
    Topics  : ["CSS Filters", "SVG Enhancements", "Performance Optimization", "File Structure Organization"],
    Status  : "Live",
    URL     : "https://youtu.be/YXcgwFMTpjA",
    Thumb   : "/media/images/tutorials/workout/WorkoutThumb_13.jpg",
    ThumbAlt: "Polishing the UI."
  },
};

export default WorkoutTutorial;
