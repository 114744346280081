import React, { useEffect, useState } from 'react';

interface ScrollAnimationProps {
  path      : string;
  frames    : number;
  zeros     : number;
  type     ?: string;
  loop     ?: boolean;
  start    ?: number;
  stop     ?: number;
  alt      ?: string;
  first    ?: number;
  className?: string;
  playTimes?: number;
}

const ScrollAnimation: React.FC<ScrollAnimationProps> = ({
  path,
  frames,
  zeros,
  type      = 'webp',
  loop      = true,
  start     = 0,
  stop      = 1,
  alt       = 'Animated Frames',
  first     = 0,
  className = '',
  playTimes = 0,
}) => {
  const [frameIndex, setFrameIndex] = useState(first);
  const [playCount, setPlayCount]   = useState(0);

  const handleScroll = () => {
    const position = window.scrollY / window.innerHeight;
    
    if (position < start) {
      setFrameIndex(first);
    } else if (position > stop && !loop) {
      setFrameIndex(first + frames - 1);
    } else if (position >= start && position <= stop) {
      let newFrameIndex  = Math.round((position - start) / (stop - start) * frames);
          newFrameIndex  = loop ? newFrameIndex % frames : Math.min(newFrameIndex, frames - 1);
          newFrameIndex += first;
      setFrameIndex(newFrameIndex);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [start, stop, loop, frames, first]);

  useEffect(() => {
      // Preload images
    for (let i = 0; i < frames; i++) {
      const img     = new Image();
            img.src = `${path}${padWithZeroes(i + first)}.${type}`;
    }
  }, [frames, path, type, first, zeros]);

  useEffect(() => {
    if (playTimes > 0 && playCount < playTimes) {
      const interval = setInterval(() => {
        setFrameIndex((prevIndex) => {
          const nextIndex = prevIndex + 1;
          if (nextIndex - first >= frames) {
            setPlayCount((prevCount) => prevCount + 1);
            return first;
          }
          return nextIndex;
        });
      }, 30); // Adjust frame rate as needed

      return () => clearInterval(interval);
    }
  }, [playTimes, playCount, frames, first]);

  const padWithZeroes = (number: number, length = zeros) => {
    let   str                       = String(number);
    while (str.length < length) str = '0' + str;
    return str;
  };

  return (
    <div className = {`gls__scroll-anim ${className}`}>
      <img
        src = {`${path}${padWithZeroes(frameIndex)}.${type}`} alt = {alt} />
    </div>
  );
};

export default ScrollAnimation;