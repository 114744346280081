import React, { useRef, useEffect } from 'react';
import { CSSTransition }            from 'react-transition-group';

import './../css/modal.css'

export type ModalContent = 
    | { src: string; alt?: string }  
    | { src: string; controls?: boolean } 
    | JSX.Element;  

interface ModalProps {
  isOpen   : boolean;
  onClose  : () => void;
  children?: React.ReactNode;
}

const ModalBody: React.FC<{ content: ModalContent | null }> = ({ content }) => {
  if (!content) return null;

  if ('alt' in content) {
    return <img src = {content.src} alt = {content.alt} />;
  } 
  else if ('controls' in content) {
    return <video controls autoPlay src = {content.src} />;
  } 
  else if (React.isValidElement(content)) { 
    return content;
  }
  return null; 
}


const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
  const dialogRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    if (dialogRef.current) {
      if (isOpen) {
        dialogRef.current.showModal();
      }
    }
  }, [isOpen]);

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  // Handle backdrop click
  const handleBackdropClick = (e: React.MouseEvent<HTMLDialogElement, MouseEvent>) => {
      if (e.target === dialogRef.current) {
      handleClose();
    }
  };

  return (
    <CSSTransition
      in         = {isOpen}
      timeout    = {500}
      classNames = 'dialog'
      nodeRef={dialogRef}
      unmountOnExit
      onExited={() => {
        if (dialogRef.current) {
          dialogRef.current.close();
        }
      }}
    >
      <dialog ref       = {dialogRef} className = 'gls__dialog-modal' onClick={handleBackdropClick}>
      <div    style     = {{ position: 'relative' }}>
      <button className = 'gls__text-button gls__modal-close' onClick = {handleClose}>
      <i      className = 'fa-duotone fa-regular fa-circle-xmark'/>
          </button>
          <div className = 'gls__modal-content'>
            {children}
          </div>
        </div>
      </dialog>
    </CSSTransition>
  );
};

export { Modal, ModalBody };

