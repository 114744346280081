import MobileGalleryItem from './MobileGalleryItem';
import React, { useState } from 'react';

import { Modal } from './../components/Modal';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';

import 'swiper/css';
import 'swiper/css/pagination';
import './../css/modal.css'

import './../css/gallery.css';

type GalleryItemData = {
  src: string;
  title: string;
  content: React.LazyExoticComponent<React.ComponentType<any>>;
  accolade?: string;
};

interface Props {
  items: Record<string, GalleryItemData>;
}

const MobileGallery: React.FC<Props> = (props) => {
  const [displayedContent, setDisplayedContent] = useState<React.ComponentType<any> | null>(null);
  const [isOpen, setIsOpen]                     = useState(false);


  const handleItemClick = (clickedItem: string) => {
    const ActiveComponent = props.items[clickedItem]?.content || null;
    setDisplayedContent(ActiveComponent);
    setIsOpen(true); // Set open state immediately
  };

  const handleClose = () => {
    setIsOpen(false); 
    setTimeout(() => {
      setDisplayedContent(null);
    }, 500);
  };

  return (
    <div className='gls__gallery gls__gallery--mobile'>
      <Swiper
        slidesPerView={1.5}
        spaceBetween={30}
        pagination={{
          clickable: false,
        }}
        centeredSlides={true}
        modules={[Pagination]}

        className={`gls__mobile-swiper${
          isOpen  ? ' open': ''
        }`}

      >
        {Object.entries(props.items).map(([name, { src, title, accolade }]) => (
          <SwiperSlide key={name}>
            <MobileGalleryItem
              onClick={() => handleItemClick(name)}
              src={src}
              accolade={accolade}
              title={title}
              style={{
                position: 'relative',
              }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <Modal isOpen={isOpen} onClose={handleClose}>
  <React.Suspense fallback={<div>Loading...</div>}>
    {displayedContent &&
      React.createElement(displayedContent, {
        onClose: handleClose,
      })}
  </React.Suspense>
</Modal>


    </div>
  );
};

export default MobileGallery;
