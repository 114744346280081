import React from 'react';

import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

import IconTypeScript from './../../media/icons/icon_typescript.svg'
import IconReact from './../../media/icons/icon_react.svg'
import IconHTML from './../../media/icons/icon_html5.svg'
import IconCSS from './../../media/icons/icon_css3.svg'
import Screenshot from './../../media/images/WorkoutThumb.jpg'

import './../../css/tutorials.css';

import WorkoutTutorial from '../../data/workoutTutorial';

const Tutorials = () => {
  return (
    <div className = "gls__tutorial-page">
      <div className = "gls__tutorial-holder">
        <ResponsiveMasonry
          columnsCountBreakPoints={{
            350 : 1,
            700 : 2,
            1050: 3,
            1400: 4,
            1750: 5,
            2400: 6,
          }}
        >
          <Masonry>
          <div className = "gls__tutorial-overview">
            <img src={Screenshot} alt="This is what we're making." />
            <div>

              <h1>Modern Front-End Masterclass: </h1>
              <h2>Designing an Apple-style Interface with React and Typescript</h2>
              <ul className="gls__tutorial-icons">
                <li><img src={IconReact} alt="" /></li>
                <li><img src={IconCSS} alt="" /></li>
                <li><img src={IconTypeScript} alt="" /></li>
                <li><img src={IconHTML} alt="" /></li>
              </ul>
              <h3>
                Join me in this 13-part masterclass as we build the Apple Fitness Plus
                data overlay from the ground up. This tutorial series will transform a
                blank slate into a polished project featuring precise detail,
                sophisticated animations, and dynamic motion. Elevate your skills and
                create something outstanding.
              </h3>
              <a href="https://www.artofsullivan.com/tutorials/workout-tutorial-assets.zip">
                <button>Get The Assets <i className="fa-regular fa-folder-arrow-down" /></button>
              </a>
              </div>
              {/* <h4>
                <strong>Learn: </strong> React, TypeScript, Advanced CSS, CSS Effects,
                State Management, External Libraries Integration, Dynamic
                Styling, Video Handling, Data Handling
              </h4> */}
            </div>
            {Object.entries(WorkoutTutorial).map(([key, value]) => (
              <a
                className = {`gls__tutorial-card ${value.Status === 'Coming Soon' ? 'soon' : '' }`}
                href      = {value.URL}
                target    = "_blank"
                rel       = "noopener noreferrer"
                style     = {{
                  textDecoration: 'none',
                  color         : 'inherit',
                  display       : 'block',
                }}
              >
                  <div className = "gls__tutorial-card--coming-soon">
                    <h1>Coming Soon</h1>
                  </div>
                <h1>{key}</h1>
                <div className = "gls__tutorial-card--image-group">
                <img src       = {value.Thumb} alt = {`${value.Title} thumbnail`} />
                  <p>{value.Duration}</p>
                </div>
                <div className = "gls__tutorial-card--info">
                  <h2>{value.Title}</h2>
                  <ul>
                    {value.Topics.map((topic, index) => (
                      <li key = {index}>{topic}</li>
                    ))}
                  </ul>
                </div>
              </a>
            ))}
          </Masonry>
        </ResponsiveMasonry>
      </div>

      {/* <div className="gls__tutorial-overview">
        <h1>Modern Front-End Masterclass: </h1>
        <h2>Designing an Apple-style Interface with React and Typescript</h2>
        <h3>
          In this 13-part masterclass, I'll guide you through the process of
          recreating the Apple Fitness Plus data overlay from scratch. This
          project-based tutorial series will take you from a blank slate to a
          fully realized project that emphasizes meticulous attention to detail,
          sophisticated animations, intentional design, and dynamic motion. Join
          me to elevate your skills and create something truly exceptional.
        </h3>
        <h4>
          <strong>Learn: </strong> React, TypeScript, Advanced CSS, CSS Effects,
          HTML, State Management, External Libraries Integration, Dynamic
          Styling, SVG Handling, Video Handling, Data Handling, Performance
          Optimization, Accessibility, Debugging and Testing, Deployment and
          Build Tools
        </h4>
      </div>
      <div className = "gls__tutorial-holder">
        <Swiper
          modules       = {[Pagination]}
          slidesPerView = {'auto'}
          pagination    = {{ clickable: true }}
        >
          {Object.entries(WorkoutTutorial).map(([key, value]) => (
            <SwiperSlide key = {key} style = {{ width: 'auto' }}>
              <a
                href   = {value.URL}
                target = "_blank"
                rel    = "noopener noreferrer"
                style  = {{
                  textDecoration: 'none',
                  color         : 'inherit',
                  display       : 'block',
                }}
              >
                <div className = "gls__tutorial-card">
                  <h1>{key}</h1>
                  <img src       = {value.Thumb} alt = {`${value.Title} thumbnail`} />
                  <div className = "gls__tutorial-card--info">
                    <h2>{value.Title}</h2>
                    <p>Duration: {value.Duration}</p>
                    <ul>
                      {value.Topics.map((topic, index) => (
                        <li key = {index}>{topic}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </a>
            </SwiperSlide>
          ))}
        </Swiper>
      </div> */}
    </div>
  );
};

export default Tutorials;
