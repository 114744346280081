import React, { useState, useEffect } from 'react';

import './../css/gradientLight.css'

interface GradientProps {
  colors: string[];
}

const GradientLight: React.FC<GradientProps> = ({ colors }) => {
  const [position, setPosition] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      setPosition({
        x: e.clientX,
        y: e.clientY,
      });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div
      className='gls__gradient-light'
        style={{
        background: `radial-gradient(at ${position.x}px ${
          position.y
        }px, ${colors.join(', ')})`,
        position: 'absolute',
      }}
    />
  );
};

export default GradientLight;
