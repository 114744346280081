import React, { useEffect, useRef, useState } from 'react';
import './../css/navigation.css';

type StyleState = {
  left ?: string;
  width?: string;
};

interface NavigationProps {
  navRef          : React.RefObject<HTMLUListElement>;
  sections        : string[];
  activeSection   : string;
  setActiveSection: React.Dispatch<React.SetStateAction<string>>;
  userInitiated: React.MutableRefObject<boolean>; 
}

const Navigation: React.FC<NavigationProps> = ({
  navRef,
  sections,
  activeSection,
  setActiveSection,
  userInitiated
}) => {
  const isScrolling    = useRef<boolean>(false);
  const lastScrollY    = useRef(0);
  const scrollVelocity = useRef(0);
  // const isInitialMount = useRef(true);

  const [hoverStyle, setHoverStyle]         = useState<StyleState>({});
  const [underlineStyle, setUnderlineStyle] = useState<StyleState>({});
  const [navClass, setNavClass]             = useState<string>('');

  useEffect(() => {
    setUnderlinePosition(activeSection);

    window.addEventListener('scroll', checkActiveSection);
    return () => window.removeEventListener('scroll', checkActiveSection);
  }, []);

  useEffect(() => {
    let resizeTimer: string | number | NodeJS.Timeout | undefined;
  
    const handleResize = () => {
      clearTimeout(resizeTimer);
  
      resizeTimer = setTimeout(() => {
        setUnderlinePosition(activeSection);
      }, 275); // Delay in milliseconds
    };
  
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      clearTimeout(resizeTimer);
    };
  }, [activeSection]); 
  

  useEffect(() => {
    const handleScroll = () => {
      if (isScrolling.current) return;

      scrollVelocity.current = window.scrollY - lastScrollY.current;
      lastScrollY.current    = window.scrollY;

      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(() => {
        if (Math.abs(scrollVelocity.current) < 5) {
          handleScrollStop();
        }
      }, 100);
    };

    let scrollTimeout: any;
    window.addEventListener('scroll', handleScroll);
    return () => {
      clearTimeout(scrollTimeout);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    // console.log(activeSection,userInitiated.current)
    // if (isInitialMount) {
    //   console.log("!!!")
    //   userInitiated.current = false;
    // } else 
    if (userInitiated.current) {
      // console.log("???")
      const targetElement = document.getElementById(activeSection);
      if (targetElement) {
        const targetY = 
          window.scrollY + targetElement.getBoundingClientRect().top;
        smoothScroll(targetY);
      }
    } 
    // else {
    //   userInitiated.current = false;
    // }
  }, [activeSection]);

  const handleNavClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    userInitiated.current = true;
    const targetId = event.currentTarget.getAttribute('data-target');
    if (targetId) {
      setActiveSection(targetId);
    }
  };

  const handleScrollStop = () => {
    const sections = Array.from(document.querySelectorAll('section[id]'));
    userInitiated.current = false;
    let closestSection: Element | null | any = null;
    let closestDistance                = Infinity;

    sections.forEach((section) => {
      const rect            = (section as HTMLElement).getBoundingClientRect();
      const distanceFromTop = rect.top;                                          // Distance from the top of the viewport.

      if (Math.abs(distanceFromTop) < closestDistance) {
        closestDistance = Math.abs(distanceFromTop);
        closestSection  = section;
      }
    });

    if (closestSection) { // Check if ID is 'Tutorials'
      const rect = (closestSection as HTMLElement).getBoundingClientRect();
      if (closestSection.id && closestSection.id !== 'Tutorials') {
        console.log(closestSection)
        smoothScroll(window.scrollY + rect.top);

      }
    }
  };

  function easeInOutCubic(t: number): number {
    return t < 0.5 ? 4 * t * t * t: (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
  }
  const checkActiveSection = () => {
    // console.log('checkActiveSection');
  
    const threshold = window.innerHeight * 0.05;
    let closestSection: Element | null = null;
    let intersectingSection: Element | null = null;
    let minDistance = Infinity;
    const sections = Array.from(document.querySelectorAll('section[id]'));
  
    sections.forEach((section) => {
      const rect = (section as HTMLElement).getBoundingClientRect();
      const distanceToViewportTop = rect.top;
  
      // Adjust the logic to better handle the distance calculation
      const adjustedDistance = Math.abs(distanceToViewportTop);
  
      if (adjustedDistance < minDistance) {
        minDistance = adjustedDistance;
        closestSection = section;
      }
  
      // Update intersecting logic to better capture the topmost section
      if (rect.top <= 0 && rect.bottom >= 0) {
        intersectingSection = section;
      }
    });
  
    // Additional logic to ensure the topmost section is recognized at the page top

    // console.log(window.scrollY)
    if (window.scrollY <= 20) {
      closestSection = sections[0]; // Assuming the first section is at the top
    }
  
    // Handle closest section logic
    if (closestSection) {
      const active = (closestSection as HTMLElement).id;
      // console.log("!!!!!!",active,activeSection)
      // if (active !== activeSection) {
        if (!userInitiated.current) {
          // userInitiated.current = false;
          // console.log("Setting active section",active)
          setActiveSection(active);
        }
        setUnderlinePosition(active);
      // }
    }
  
    // Handle intersecting section logic
    if (intersectingSection) {
      const intersecting = (intersectingSection as HTMLElement).id;
      setNavClass(intersecting.toLowerCase().replace(/\s+/g, '-'));
    }
  };
  

  const smoothScroll = (targetY: number) => {
    isScrolling.current = true;

    const duration      = 1500;
    const frameDuration = 1000 / 60;
    const totalFrames   = Math.round(duration / frameDuration);
    let   frame         = 0;

    const startY = window.scrollY;

    const animateScroll = () => {
      frame++;
      const progress       = easeInOutCubic(frame / totalFrames);
      const difference     = targetY - startY;
      const perFrameScroll = progress * difference;

      window.scrollTo(0, startY + perFrameScroll);

      if (frame < totalFrames) {
        requestAnimationFrame(animateScroll);
      } else {
        // userInitiated.current = false;
        setTimeout(() => {
          isScrolling.current = false;
        }, 300);
      }
    };

    requestAnimationFrame(animateScroll);
  };

  const setUnderlinePosition = (section: string) => {
    // console.log('setUnderlinePosition', section);
    const navItem = document.querySelector(`button[data-target='${section}']`);
    if (navItem && navRef.current) {
      const bounds       = navItem.getBoundingClientRect();
      const parentBounds = navRef.current.getBoundingClientRect();
      const left         = bounds.left - parentBounds.left;
      const width        = bounds.width;
      setUnderlineStyle({
        left : `${left}px`,
        width: `${width}px`,
      });
    }
  };

  const handleMouseEnter = (event: React.MouseEvent<HTMLButtonElement>) => {
    const link         = event.currentTarget;
    const bounds       = link.getBoundingClientRect();
    const parentBounds = navRef.current!.getBoundingClientRect();
    const left         = bounds.left - parentBounds.left;
    const width        = bounds.width;

    setHoverStyle({
      left : `${left}px`,
      width: `${width}px`,
    });
  };

  return (
    <nav className = {`gls__nav ${navClass}`}>
    <ul  ref       = {navRef}>
        {sections.map(
          (
            text  // Use the sections prop
          ) => (
            <li key = {text}>
              <button
                onMouseEnter = {handleMouseEnter}
                data-target  = {text}
                onClick      = {handleNavClick}
                role         = "link"
                aria-label   = {`Navigate to ${text} section`}
                className    = {activeSection === text ? 'active' : ''}  // Highlight the active section
              >
                {text}
              </button>
            </li>
          )
        )}
        <span
          className = "gls__nav__underline gls__nav__underline--hover"
          style     = {hoverStyle}
        ></span>
        <span
          className = "gls__nav__underline gls__nav__underline--active"
          style     = {underlineStyle}
        ></span>
      </ul>
    </nav>
  );
};

export default Navigation;
