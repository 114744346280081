import React, { useEffect, useRef, useState } from "react";
import GalleryItem                            from "./GalleryItem";

import './../css/gallery.css'

type GalleryItemData = {
  src      : string;
  title    : string;
  content  : React.LazyExoticComponent<React.ComponentType<any>>;
  accolade?: string;
};

interface Props {
  items: Record<string, GalleryItemData>;
}

const Gallery: React.FC<Props> = (props) => {
  type Properties = {
    top       : number;
    left      : number;
    width    ?: string | number;
    transform?: any;
    zIndex   ?: number;
  };

  type GalleryState = "active" | "stacked" | "default";

  const itemPlaceHolders        = useRef<Record<string, HTMLDivElement | null>>({});
  const galleryHolder           = useRef<HTMLDivElement | null>(null);
  const stackReferenceObjectRef = useRef<HTMLDivElement | null>(null);
  const heroReferenceObjectRef  = useRef<HTMLDivElement | null>(null);

  const [displayedContent, setDisplayedContent]     = useState<React.ComponentType<any> | null>(null);
  const [zIndexes, setZIndexes]                     = useState<Record<string, number>>({});
  const [itemWidth, setItemWidth]                   = useState<number>(10);
  const [isContentDisplayed, setIsContentDisplayed] = useState(false);
  const [breakAfterIndex, setBreakAfterIndex]       = useState<number>(0);

  const [itemsDetails, setItemsDetails] = useState<{
    activeItem : string | null;
    galleryOpen: boolean;
    properties : Properties[];
    itemStates : Record<string, GalleryState>;
  }>({
    activeItem : null,
    galleryOpen: false,
    properties : [],
    itemStates : {},
  });

    // Position gallery card placeholders in item change or resize.
  useEffect(() => {
    function calculateLayout() {

      // console.log("calculateLayout")

      if (galleryHolder.current) {

        // console.log("galleryHolder.current","isContentDisplayed:",isContentDisplayed)

        setItemWidth(
          getMaxItemWidth(
            1.775,
            galleryHolder.current.offsetWidth * 0.9,
            galleryHolder.current.offsetHeight * 0.9,
            Object.keys(props.items).length
          )
        );

          // Compute the number of items that can fit horizontally
        const horizontalItemCount = Math.floor(
          (galleryHolder.current.offsetWidth * 0.9) / itemWidth
        );

        // Compute the number of rows based on total items and horizontalItemCount
        const totalItems = Object.keys(props.items).length;

        // Compute the initial count for the last row
        const initialLastRowCount = totalItems % horizontalItemCount;

        // If the last row is not empty and isn't full
        if (
          initialLastRowCount !== 0 &&
          initialLastRowCount !== horizontalItemCount
        ) {
            // Compute the difference between the first and last rows
        }

        let calculatedBreakAfterIndex = horizontalItemCount;

        if (
          initialLastRowCount !== 0 &&
          initialLastRowCount !== horizontalItemCount
        ) {
          const difference  = horizontalItemCount - initialLastRowCount;
          const itemsToMove = Math.floor(difference / 2);

          calculatedBreakAfterIndex = horizontalItemCount - itemsToMove;
        }

        if (breakAfterIndex !== calculatedBreakAfterIndex) {
          // console.log("1")
          setBreakAfterIndex(calculatedBreakAfterIndex);
          // if (!isContentDisplayed) {
          //   console.log("2")
          //   setTimeout(() => {
          //     placeGalleryItems();
          //   }, 100);
          // }
        }
      }
    }

    const debouncedCalculateLayout = debounce(calculateLayout, 150);
    debouncedCalculateLayout();

    window.addEventListener("resize", debouncedCalculateLayout);

    if (!isContentDisplayed) {
      placeGalleryItems();
    }

    return () => window.removeEventListener("resize", debouncedCalculateLayout);
  }, [props.items, itemWidth]);

  function debounce(func: Function, wait: number) {
    let timeout: NodeJS.Timeout;
    return (...args: any[]) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), wait);
    };
  }

  function getMaxItemWidth(
    aspectRatio    : number,
    containerWidth : number,
    containerHeight: number,
    itemCount      : number
  ) {
    let minPossibleWidth = 0;
    let maxPossibleWidth = containerWidth;
    let optimalWidth     = 0;

    while (maxPossibleWidth - minPossibleWidth > 1e-4) {
      let currentWidth = (minPossibleWidth + maxPossibleWidth) / 2;

      let horizontalItemCount = Math.floor(containerWidth / currentWidth);
      let verticalItemCount   = Math.floor(
        containerHeight / (currentWidth * aspectRatio)
      );

      if (horizontalItemCount * verticalItemCount >= itemCount) {
        optimalWidth     = currentWidth;
        minPossibleWidth = currentWidth;
      } else {
        maxPossibleWidth = currentWidth;
      }
    }

    return optimalWidth;
  }

  function placeGalleryItems() {
    
    // console.log("placeGalleryItems")

    if (galleryHolder.current && !itemsDetails.galleryOpen) {
      const galleryRect = galleryHolder.current.getBoundingClientRect();

      const newPositions: Properties[]       = [];
      const defaultItemStates: Record<string, GalleryState> = {};

      Object.entries(itemPlaceHolders.current).forEach(([key, nullRef]) => {
        if (nullRef) {
          const itemRect = nullRef.getBoundingClientRect();
          newPositions.push({
            top: 
              itemRect.top -
              galleryRect.top +
              (galleryHolder.current?.scrollTop || 0),
            left: 
              itemRect.left -
              galleryRect.left +
              (galleryHolder.current?.scrollLeft || 0),
          });
        } else {
          newPositions.push({ top: 0, left: 0 });
        }
        defaultItemStates[key] = "default";
      });

      setItemsDetails((prevDetails) => ({
        ...prevDetails,
        properties: newPositions,
        itemStates: defaultItemStates,
      }));
    }
  }

  const closeActiveItem = () => {
    
    // console.log("closeActiveItem")

    const newProperties: Properties[]  = [];
    const newItemStates: Record<string, GalleryState> = {};

    Object.entries(itemPlaceHolders.current).forEach(([key, nullRef]) => {
      if (nullRef && galleryHolder.current) {
        const itemRect    = nullRef.getBoundingClientRect();
        const galleryRect = galleryHolder.current.getBoundingClientRect();
        newProperties.push({
          top: 
            itemRect.top -
            galleryRect.top +
            (galleryHolder.current?.scrollTop || 0),
          left: 
            itemRect.left -
            galleryRect.left +
            (galleryHolder.current?.scrollLeft || 0),
          zIndex: 0,
        });
        newItemStates[key] = "default";
      }
    });

    setIsContentDisplayed(false);

    setItemsDetails({
      activeItem : null,
      galleryOpen: false,
      properties : newProperties,
      itemStates : newItemStates,
    });

      // Delay setting z-indexes to 0 by 500ms
    setTimeout(() => {
      const resetZIndexes: Record<string, number> = {};
      Object.keys(itemPlaceHolders.current).forEach((key) => {
        resetZIndexes[key] = 0;
      });
      setZIndexes(resetZIndexes);
    }, 500);

  };

  const onContentTransitionEnd = (
    event: React.TransitionEvent<HTMLDivElement>
  ) => {
    if (!isContentDisplayed) {
    }
  };

  const handleItemClick = (clickedItem: string) => {

    // console.log("handleItemClick")
    
    if (isContentDisplayed) {
      if (clickedItem === itemsDetails.activeItem) {
        closeActiveItem();
      } else {
        setItemsDetails((prevDetails) => ({
          ...prevDetails,
          galleryOpen: false,
        }));
        setTimeout(() => {
          setItemsDetails((prevDetails) => ({
            ...prevDetails,
            galleryOpen: true,
          }));
            // After a small delay to let the closing animation play, activate the clicked item
          activateItem(clickedItem);
        }, 500);
      }
      return;
    }
    activateItem(clickedItem);
  };

  const activateItem = (clickedItem: string) => {

    // console.log("activateItem")

    let stackedReferencePosition: Properties | undefined;
    const galleryRect = galleryHolder.current?.getBoundingClientRect();

    const newProperties: Properties[]  = [];
    const newItemStates: Record<string, GalleryState> = {};

    const totalRotation              = 30; // total fan angle
    const keys                       = Object.keys(props.items);
    const clickedIndex               = keys.indexOf(clickedItem);
    const newZIndexes: Record<string, number> = {};

    keys.forEach((key, index) => {
      if (index === clickedIndex) {
        newZIndexes[key] = 1000;
      } else if (index > clickedIndex) {
        newZIndexes[key] = 1000 - (index - clickedIndex);
      } else {
        newZIndexes[key] = 1000 - (keys.length + index - clickedIndex);
      }
    });

      // Immediately set the z-index values
    setZIndexes(newZIndexes);

      // If the clicked item is the active item, reset everything
    if (clickedItem === itemsDetails.activeItem) {
      Object.entries(itemPlaceHolders.current).forEach(([key, nullRef]) => {
        if (nullRef && galleryRect) {
          const itemRect = nullRef.getBoundingClientRect();
          newProperties.push({
            top: 
              itemRect.top -
              galleryRect.top +
              (galleryHolder.current?.scrollTop || 0),
            left: 
              itemRect.left -
              galleryRect.left +
              (galleryHolder.current?.scrollLeft || 0),
          });
          newItemStates[key] = "default";
        }
      });
      setItemsDetails({
        activeItem : null,
        galleryOpen: false,
        properties : newProperties,
        itemStates : newItemStates,
      });
    } else {
      const ActiveComponent = props.items[clickedItem]?.content || null;
      setDisplayedContent(ActiveComponent);
      setIsContentDisplayed(true);

      if (
        galleryHolder.current &&
        stackReferenceObjectRef.current &&
        galleryRect
      ) {
        const referenceRect = 
          stackReferenceObjectRef.current.getBoundingClientRect();
        stackedReferencePosition = {
          top: 
            referenceRect.top -
            galleryRect.top +
            (galleryHolder.current?.scrollTop || 0),
          left: 
            referenceRect.left -
            galleryRect.left +
            (galleryHolder.current?.scrollLeft || 0),
        };
      }

      const heroRefPosition = 
        heroReferenceObjectRef.current?.getBoundingClientRect();

      const rotationIncrement = 
        totalRotation / (Object.keys(props.items).length - 1);
      let currentRotation = totalRotation / -2;

      const keys                         = Object.keys(props.items);
      const clickedIndex                 = keys.indexOf(clickedItem);
      const newZIndexes: Record<string,   number>       = {};
      const newProperties: Properties[]  = [];
      const newItemStates: Record<string, GalleryState> = {};

      keys.forEach((name, i) => {
        if (name === clickedItem && heroRefPosition && galleryRect) {
          newProperties.push({
            top: 
              heroRefPosition.top -
              galleryRect.top +
              (galleryHolder.current?.scrollTop || 0),
            left: 
              heroRefPosition.left -
              galleryRect.left +
              (galleryHolder.current?.scrollLeft || 0),
            width    : heroRefPosition.width,
            transform: `rotate(0deg)`,
          });
          newItemStates[name] = "active" as GalleryState;
          newZIndexes[name]   = 1000;
        } else {
          if (stackedReferencePosition) {
            newProperties.push({
              top      : stackedReferencePosition.top,
              left     : stackedReferencePosition.left,
              width    : itemWidth,
              transform: `rotate(${currentRotation}deg)`,
            });
          }
          currentRotation += rotationIncrement;

          if (i > clickedIndex) {
            newZIndexes[name] = 1000 - (i - clickedIndex);
          } else {
            newZIndexes[name] = 1000 - (keys.length + i - clickedIndex);
          }
          newItemStates[name] = "stacked" as GalleryState;
        }
      });

      setZIndexes(newZIndexes);
      setItemsDetails({
        activeItem : clickedItem as string | null,
        galleryOpen: true,
        properties : newProperties,
        itemStates : newItemStates,
      });
    }
  };

  return (
    <div className = "gls__gallery" ref = {galleryHolder}>
      <div
        className = "gls__gallery-stack-ref"
        ref       = {stackReferenceObjectRef}
      ></div>
      <div
        className = "gls__gallery-hero-ref"
        ref       = {heroReferenceObjectRef}
        style     = {{ width: itemWidth }}
      ></div>
      <div
        className={`gls__gallery-body${
          itemsDetails.galleryOpen ? " open": ""
        }`}
        onTransitionEnd={(event) => {
          onContentTransitionEnd(event);
          if (!isContentDisplayed) {
            setDisplayedContent(null);
          }
        }}
        style = {{ width: `calc(100% - ${itemWidth}px - 4rem)` }}
      >
        <React.Suspense fallback = {<div>Loading...</div>}>
          {displayedContent &&
            React.createElement(displayedContent, {
              onClose: closeActiveItem,
            })}
        </React.Suspense>
      </div>
      <div className = "gls__gallery-thumb-holder">
        {Object.entries(props.items).map(
          ([name, { src, title, accolade }], index) => (
            <React.Fragment key = {name}>
              <div
                ref       = {(el) => (itemPlaceHolders.current[name] = el)}
                className = "gls__gallery__null"
                style     = {{ width: itemWidth }}
              ></div>

              <br
                style={
                  index === breakAfterIndex - 1
                    ? { display: "block" }
                    :  { display: "none" }
                }
              />

              <GalleryItem
                onClick   = {() => handleItemClick(name)}
                src       = {src}
                accolade  = {accolade}
                title     = {title}
                itemState = {itemsDetails.itemStates[name] || "default"}
                style     = {{
                  width    : itemsDetails.properties[index]?.width || itemWidth,
                  top      : `${itemsDetails.properties[index]?.top || 0}px`,
                  left     : `${itemsDetails.properties[index]?.left || 0}px`,
                  transform: itemsDetails.properties[index]?.transform || "",
                  zIndex   : zIndexes[name] || 0,
                }}
              />
            </React.Fragment>
          )
        )}
      </div>
    </div>
  );
};

export default Gallery;
