import ScrollAnimation from "./../components/ScrollAnimation";

import GregSullivan from './../media/images/greg-sullivan.jpg';
import Signature from './../media/images/signature.png';

import './../css/landing.css';
import { SetStateAction, useEffect, useState } from 'react';

interface LandingProps {
  setActiveSection: (message: string) => void;
}

const Landing: React.FC<LandingProps> = ({ setActiveSection }) => {
  const [activeTab, setActiveTab] = useState('Praise');
  const [fade, setFade]           = useState(true);

  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 600);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 950);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);


  useEffect(() => {
    const timeoutId = setTimeout(() => setFade(true), 10);  // Start fade in
    return () => clearTimeout(timeoutId);
  }, [activeTab]);

  const handleTabChange = (tab: SetStateAction<string>) => {
    if (tab === activeTab) {
      return;
    }

    setFade(false);
    setTimeout(() => setActiveTab(tab), 300);
  };

  return (
    <>
      <div className = "gls__about">
      {isWideScreen && (
        <ScrollAnimation
          path      = {'/media/images/lander-turn/lander'}
          frames    = {100}
          zeros     = {3}
          loop      = {true}
          start     = {0}
          stop      = {1}
          first     = {0}
          className = "gls__lander"
          playTimes = {0}
        />
      )}
        <div className = "gls__about__header">
          <button
            className = "gls__icon-button"
            onClick   = {() => setActiveSection('Portfolio')}
          >
            <span>
              <i className = "fa-duotone fa-fw fa-images" />
            </span>
            Portfolio
            <span>
              <i className = "fa-duotone fa-fw fa-down" />
            </span>
          </button>
          <div className = "gls__about__header-image">
          <img src       = {GregSullivan} alt = "Greg Sullivan's funky mug" />
          </div>
          <button
            className = "gls__icon-button"
            onClick   = {() => setActiveSection('Contact')}
          >
            <span>
              <i className = "fa-duotone fa-fw fa-messages" />
            </span>
            Contact
            <span>
              <i className = "fa-duotone fa-fw fa-down" />
            </span>
          </button>
          {/* <h3>Contact</h3> */}
        </div>
        <div>
          <h1>Greg Sullivan</h1>
          <h2>Creative & Technical Leader</h2>
        </div>
        <div className = "gls__about__tab-group">
          <ul>
            {['Skills', 'Praise', 'About'].map((tab) => (
              <li
                key       = {tab}
                className = {
                  activeTab === tab
                    ? 'gls__icon-button active'
                    :   'gls__icon-button'
                }
                onClick = {() => handleTabChange(tab)}
              >
                {tab}
              </li>
            ))}
          </ul>
          <div
            className = {`gls__about__tab-body ${fade ? 'fade-in' : 'fade-out'}`}
          >
            {activeTab === 'Skills' && (
              <div className = "gls__about-scroll">
              <div className = "gls__skills-category">
                  <h3>Creative & Design</h3>
                  <ul className = "gls__skills-list">
                    <li>Adobe Creative Suite</li>
                    <li>3D Animation & Motion Graphics</li>
                    <li>UX/UI Design</li>
                    <li>Brand Identity & Copywriting</li>
                  </ul>
                </div>
                <div className = "gls__skills-category">
                  <h3>Technical Expertise</h3>
                  <ul className = "gls__skills-list">
                    <li>Web Development (React, TypeScript, HTML5, CSS)</li>
                    <li>Emerging Tech (AI/ML, VR)</li>
                    <li>Technical Animation Production</li>
                    <li>Software Architecture & Optimization</li>
                  </ul>
                </div>
                <div className = "gls__skills-category">
                  <h3>Leadership & Strategy</h3>
                  <ul className = "gls__skills-list">
                    <li>Team Building & Leadership</li>
                    <li>Strategic Planning & Problem-Solving</li>
                    <li>Project Management</li>
                    <li>Startup Growth & Fundraising</li>
                  </ul>
                </div>
                <div className = "gls__skills-category">
                  <h3>Communication</h3>
                  <ul className = "gls__skills-list">
                    <li>Public Speaking & Presentations</li>
                    <li>Visual Storytelling & Brand Campaigns</li>
                    <li>Internal & External Communications</li>
                  </ul>
                </div>
                <div className = "gls__skills-category">
                  <h3>Innovation</h3>
                  <ul className = "gls__skills-list">
                    <li>Interactive Media & Gaming Platforms</li>
                    <li>VR/AR Experience Design</li>
                    <li>Investment Platform Innovation</li>
                  </ul>
                </div>
              </div>
            )}

            {activeTab === 'Praise' && (
              <div className = "gls__about-scroll">
              <div className = "gls__pull-quote">
                  <div>
                    <p>
                      <i className = "fa-solid fa-quote-left"></i>
                      Greg is one of the few creative leaders I have worked with
                      time and time again who consistently produces amazing
                      experiences and delightful applications.
                      <i className = "fa-solid fa-quote-right"></i>
                    </p>
                  </div>
                  <h2>Awah Teh</h2>
                  <h3>Capital One</h3>
                  <h4>Data Governance & Privacy Engineering Executive</h4>
                </div>

                <div className = "gls__pull-quote">
                  <div>
                    <p>
                      <i className = "fa-solid fa-quote-left"></i>
                      [Greg] has the unique ability to take whatever is thrown
                      at him, no matter how abstract or confusing, and turn it
                      into exactly what you were thinking but were unable to
                      articulate.
                      <i className = "fa-solid fa-quote-right"></i>
                    </p>
                  </div>
                  <h2>Hector Tantoh</h2>
                  <h3>Yahoo</h3>
                  <h4>Chief of Staff</h4>
                </div>

                <div className = "gls__pull-quote">
                  <div>
                    <p>
                      <i className = "fa-solid fa-quote-left"></i>
                      Greg is an elite player. He is the go-to person when you
                      need to take creative ideas from zero to reality.
                      <i className = "fa-solid fa-quote-right"></i>
                    </p>
                  </div>
                  <h2>Matthew Hall</h2>
                  <h3>Surf Air Mobility</h3>
                  <h4>Business Executive, Advisor</h4>
                </div>

                <div className = "gls__pull-quote">
                  <div>
                    <p>
                      <i className = "fa-solid fa-quote-left"></i>
                      [Greg] is one of those rare creatives that can actually
                      conceptualize not only what something should look like but
                      HOW it should work.
                      <i className = "fa-solid fa-quote-right"></i>
                    </p>
                  </div>
                  <h2>Joey Caroni</h2>
                  <h3>XPrize</h3>
                  <h4>Head of Creative</h4>
                </div>

                <div className = "gls__pull-quote">
                  <div>
                    <p>
                      <i className = "fa-solid fa-quote-left"></i>
                      Greg is a master at his profession and I look forward to
                      working with him again soon.
                      <i className = "fa-solid fa-quote-right"></i>
                    </p>
                  </div>
                  <h2>Rex Olson</h2>
                  <h3>NewTek</h3>
                  <h4>VP of Marketing</h4>
                </div>
              </div>
            )}

            {activeTab === 'About' && (
              <div className = "gls__about-scroll gls__letter">
                <h3>Hello!</h3>
                <p>
                  I am Greg Sullivan, an Emmy winning{' '}
                  <span className = "gls__paragraph-under">
                    creative director, technical director, and mentor
                  </span>
                  . I've electrified eco-transport at Hive, translated space
                  lingo at JPL/NASA, merged sneakers with stocks at Rares, and
                  helped kids through the pandemic with a dragon. I believe in
                  telling stories that engage, educate, and inspire.
                </p>
                <p>Get in touch, let's tell some stories together.</p>
                <div>
                  <img src = {Signature} alt = "" />
                  <p>- Greg S.</p>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* <div className = "gls__links">
          <div>
            <i className = "fa-fw fa-brands fa-github" />
            <span>Git</span>
          </div>
          <div>
            <i className = "fa-fw fa-brands fa-linkedin-in" />
            <span>Profile</span>
          </div>
          <div>
            <i className = "fa-fw fa-duotone fa-book" />
            <span>Writing</span>
          </div>
        </div> */}
      </div>

      {/* <div className="gls__absolute-content">
          <div className = "gls__section__sub gls__section__sub--name">


            <ScrollAnimation
              path      = {"/media/images/big-name/GregSullivan"}
              frames    = {28}
              zeros     = {2}
              loop      = {false}
              start     = {0}
              stop      = {0.5}
              first     = {0}
              className = "gls__big-name"
            />
          </div>
          <div className = "gls__section__sub gls__section__sub--intro">
            <div>
              <h1>
                Professional Creativity,
                <br />
                Depth, and Experience.
              </h1>
              <div>
                <p>
                  I am Greg Sullivan, an Emmy winning {" "}
                  <span className = 'gls__paragraph-under'>
                    creative director, technical director, and mentor
                  </span>
                  . I've electrified eco-transport at Hive, translated space
                  lingo at JPL/NASA, merged sneakers with stocks at Rares, and
                  helped kids through the pandemic with a dragon. I believe in
                  telling stories that engage, educate, and inspire.
                </p>
                <p>Get in touch, let's tell some stories together.</p>
              </div>
            </div>
          </div>

          <div></div>
        </div>
        <div className = "gls__scroll-down">
        <i   className = "fa-fw fa-duotone fa-circle-down" />
        </div> */}
    </>
  );
};

export default Landing;
