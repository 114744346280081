import React, { useState, useEffect } from 'react';
import './../css/rotateDevice.css'

const RotateDevice = () => {
  const [isLandscape, setIsLandscape] = useState(false);
  const [rotationDirection, setRotationDirection] = useState('');
  const [isMobileDevice, setIsMobileDevice] = useState(false);

  useEffect(() => {
    const checkIfMobileDevice = () => {
      // Simple check for mobile devices
      setIsMobileDevice(
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
      );
    };

    const handleOrientationChange = () => {
      const mql = window.matchMedia('(orientation: landscape)');

      setIsLandscape(mql.matches);

      if (window.orientation === -90) {
        setRotationDirection('counterclockwise');
      } else if (window.orientation === 90) {
        setRotationDirection('clockwise');
      } else {
        setRotationDirection('');
      }
    };

    checkIfMobileDevice();
    window.addEventListener('resize', handleOrientationChange);
    handleOrientationChange();

    return () => {
      window.removeEventListener('resize', handleOrientationChange);
    };
  }, []);

  return (
    <>
      {isLandscape && isMobileDevice && (
        <div className="gls__rotate-screen">
          <h1>Pssst...</h1>
          {rotationDirection === "clockwise" ? 
          <i className="fa-solid fa-arrow-rotate-right fa-spin"></i>
          :
          <i className="fa-solid fa-arrow-rotate-left fa-spin-reverse fa-spin"></i>
          }
          <h3>Rotate your device {rotationDirection} to continue.</h3>
        </div>
      )}
    </>
  );
};

export default RotateDevice;
