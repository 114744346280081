import React    from 'react';
import ReactDOM from 'react-dom/client';

import App      from './App';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js').then(registration => {
      console.log('SW registered: ', registration);
    }).catch(registrationError => {
      console.log('SW registration failed: ', registrationError);
    });
  });
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);